var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.types.js");require("./metabase.util.malli.registry.js");
'use strict';var sG,tG,vG,wG,xha,yha,zha,xG,uG;$CLJS.qG=function(a,b){return $CLJS.cc($CLJS.cb(function(c,d){var e=a.h?a.h(d):a.call(null,d);return $CLJS.Zf.j(c,e,$CLJS.Yd.g($CLJS.J.j(c,e,$CLJS.xf),d))},$CLJS.ac($CLJS.N),b))};$CLJS.rG=function(a,b){return $CLJS.cb(function(c,d){return $CLJS.n(a.h?a.h(d):a.call(null,d))?$CLJS.Oc(d):null},null,b)};
sG=function(a,b){$CLJS.I(a,0,null);$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);b=$CLJS.Dz(b,$CLJS.tk)?$CLJS.bG:$CLJS.Dz(b,$CLJS.Lk)?$CLJS.cD:$CLJS.Dz(b,$CLJS.Ak)?$CLJS.dD:null;return $CLJS.n(b)?$CLJS.qE(b,a):!0};
tG=function(a){a=$CLJS.y(a);$CLJS.z(a);a=$CLJS.B(a);$CLJS.z(a);a=$CLJS.B(a);a=$CLJS.qG(function(d){return $CLJS.Dz($CLJS.ND(d),$CLJS.aC)},a);var b=$CLJS.O(a);a=$CLJS.J.g(b,!1);b=$CLJS.J.g(b,!0);if($CLJS.Sj.g($CLJS.D(a),1))return"Temporal arithmetic expression must contain exactly one non-interval value";if(1>$CLJS.D(b))return"Temporal arithmetic expression must contain at least one :interval";var c=$CLJS.ND($CLJS.z(a));return $CLJS.Me(function(d){$CLJS.I(d,0,null);$CLJS.I(d,1,null);$CLJS.I(d,2,null);
var e=$CLJS.I(d,3,null);return $CLJS.n(sG(d,c))?null:["Cannot add a ",$CLJS.p.h(e)," interval to a ",$CLJS.p.h(c)," expression"].join("")},b)};
vG=function(a){return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.kr,new $CLJS.h(null,1,[$CLJS.ts,[$CLJS.p.h(a)," clause with a temporal expression and one or more :interval clauses"].join("")],null),new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.Tq,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Hq,a],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.wD],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Rq,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,uG],null)],
null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.WD],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Rq,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,uG],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vi,new $CLJS.h(null,1,[$CLJS.Es,function(b){b=$CLJS.O(b);b=$CLJS.J.g(b,$CLJS.Zi);return["Invalid ",$CLJS.p.h(a)," clause: ",$CLJS.p.h(tG(b))].join("")}],null),$CLJS.Oe(tG)],null)],null)};
wG=function(a){return new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Tq,new $CLJS.h(null,1,[$CLJS.ts,[$CLJS.p.h(a)," clause with numeric args"].join("")],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Hq,a],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.wD],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Rq,new $CLJS.h(null,1,[$CLJS.ni,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.jE],null)],null)],null)],null)};
xha=function(a){return $CLJS.Md($CLJS.tE,$CLJS.cf.g(function(b){var c=$CLJS.ND(b),d=$CLJS.Dz(c,$CLJS.$D);b=d?$CLJS.qE($CLJS.pF,b):d;return $CLJS.n(b)?$CLJS.fj:c},a))};yha=function(a){a=$CLJS.rG(function(b){return!$CLJS.Dz(b,$CLJS.aC)},$CLJS.cf.g($CLJS.ND,a));return $CLJS.Dz(a,$CLJS.$D)?$CLJS.kj:a};
zha=function(a,b){return $CLJS.n($CLJS.Me(function(c){return $CLJS.Dz($CLJS.ND(c),$CLJS.aC)},b))?yha(b):$CLJS.E.g(a,$CLJS.Zr)&&$CLJS.E.g($CLJS.D(b),2)&&($CLJS.Le(function(c){return $CLJS.Dz($CLJS.ND(c),$CLJS.tk)},b)||$CLJS.Le(function(c){return $CLJS.Dz($CLJS.ND(c),$CLJS.Ak)},b))?$CLJS.aC:xha(b)};xG=new $CLJS.M("metabase.lib.schema.expression.arithmetic","args.numbers","metabase.lib.schema.expression.arithmetic/args.numbers",-179216621);
$CLJS.yG=new $CLJS.M("lib.type-of","type-is-type-of-arithmetic-args","lib.type-of/type-is-type-of-arithmetic-args",-626871615);uG=new $CLJS.M("mbql.clause","interval","mbql.clause/interval",-990446354);$CLJS.Y(new $CLJS.M("metabase.lib.schema.expression.arithmetic","args.temporal","metabase.lib.schema.expression.arithmetic/args.temporal",1338604923),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Wq,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.UD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.WD],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[new $CLJS.M(null,"intervals","intervals",2096054013),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jq,new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.W,uG],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vi,new $CLJS.h(null,1,[$CLJS.ts,"Temporal arithmetic expression with valid interval units for the expression type"],null),function(a){var b=$CLJS.y(a);a=$CLJS.z(b);b=$CLJS.B(b);var c=$CLJS.ND(a);return $CLJS.Le(function(d){return sG(d,c)},b)}],null)],null));
$CLJS.Y(xG,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Rq,new $CLJS.h(null,1,[$CLJS.ni,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.jE],null)],null)],null));
var Aha=new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.Tq,new $CLJS.h(null,1,[$CLJS.ts,":- clause taking the difference of two temporal expressions"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Hq,$CLJS.Zr],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.wD],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.WD],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.WD],null)],null)],
null);$CLJS.KF.g($CLJS.Jq,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.er,vG($CLJS.Jq),wG($CLJS.Jq)],null));$CLJS.KF.g($CLJS.Zr,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.er,vG($CLJS.Zr),Aha,wG($CLJS.Zr)],null));$CLJS.AE($CLJS.Kq,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Kr,xG],null)]));$CLJS.AE($CLJS.FF,$CLJS.H([$CLJS.Zr,$CLJS.LC,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Kr,xG],null)]));
for(var zG=$CLJS.y(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Jq,$CLJS.Zr,$CLJS.Kq],null)),AG=null,BG=0,CG=0;;)if(CG<BG){var Bha=AG.X(null,CG);$CLJS.rE(Bha,$CLJS.yG);CG+=1}else{var DG=$CLJS.y(zG);if(DG){var EG=DG;if($CLJS.vd(EG)){var FG=$CLJS.ic(EG),Cha=$CLJS.jc(EG),Dha=FG,Eha=$CLJS.D(FG);zG=Cha;AG=Dha;BG=Eha}else{var Fha=$CLJS.z(EG);$CLJS.rE(Fha,$CLJS.yG);zG=$CLJS.B(EG);AG=null;BG=0}CG=0}else break}
$CLJS.MD.m(null,$CLJS.yG,function(a){var b=$CLJS.y(a);a=$CLJS.z(b);b=$CLJS.B(b);$CLJS.z(b);b=$CLJS.B(b);return zha(a,b)});$CLJS.yE($CLJS.LE,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.jE],null)],null)]));$CLJS.rE($CLJS.LE,$CLJS.dE);
for(var GG=$CLJS.y(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.HF,$CLJS.CE,$CLJS.ZE],null)),HG=null,IG=0,JG=0;;)if(JG<IG){var Gha=HG.X(null,JG);$CLJS.yE(Gha,$CLJS.H([$CLJS.Zr,$CLJS.LC,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.jE],null)],null)]));JG+=1}else{var KG=$CLJS.y(GG);if(KG){var LG=KG;if($CLJS.vd(LG)){var MG=$CLJS.ic(LG),Hha=$CLJS.jc(LG),Iha=MG,Jha=$CLJS.D(MG);GG=Hha;HG=Iha;IG=Jha}else{var Kha=$CLJS.z(LG);$CLJS.yE(Kha,$CLJS.H([$CLJS.Zr,$CLJS.LC,new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.jE],null)],null)]));GG=$CLJS.B(LG);HG=null;IG=0}JG=0}else break}
for(var NG=$CLJS.y(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.GE,$CLJS.jF,$CLJS.qF],null)),OG=null,PG=0,QG=0;;)if(QG<PG){var Lha=OG.X(null,QG);$CLJS.yE(Lha,$CLJS.H([$CLJS.Zr,$CLJS.Ti,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.jE],null)],null)]));QG+=1}else{var RG=$CLJS.y(NG);if(RG){var SG=RG;if($CLJS.vd(SG)){var TG=$CLJS.ic(SG),Mha=$CLJS.jc(SG),Nha=TG,Oha=$CLJS.D(TG);NG=Mha;OG=Nha;PG=Oha}else{var Pha=$CLJS.z(SG);$CLJS.yE(Pha,$CLJS.H([$CLJS.Zr,$CLJS.Ti,new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.jE],null)],null)]));NG=$CLJS.B(SG);OG=null;PG=0}QG=0}else break}$CLJS.yE($CLJS.IE,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.jE],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.jE],null)],null)]));
$CLJS.MD.m(null,$CLJS.IE,function(a){$CLJS.I(a,0,null);$CLJS.I(a,1,null);var b=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return $CLJS.Dz($CLJS.ND(b),$CLJS.Ti)&&$CLJS.Dz($CLJS.ND(a),$CLJS.Ti)?$CLJS.Ti:$CLJS.LC});