var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.lib.aggregation.js");require("./metabase.lib.binning.js");require("./metabase.lib.card.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.equality.js");require("./metabase.lib.expression.js");require("./metabase.lib.join.js");require("./metabase.lib.join.util.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.normalize.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.remove_replace.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.metadata.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.types.isa.js");require("./metabase.lib.util.js");require("./shadow.js.shim.module$ttag.js");require("./metabase.shared.util.time.js");require("./metabase.util.js");require("./metabase.util.humanization.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");require("./metabase.util.malli.registry.js");
'use strict';var l7,m7,n7,nta,ota,pta,q7,r7,t7,u7,qta,rta,sta,w7,x7,y7,tta,uta;l7=function(a){var b=new $CLJS.h(null,3,[$CLJS.yD,$CLJS.p.h($CLJS.JD()),$CLJS.tA,$CLJS.tA.h(a),$CLJS.ki,$CLJS.zz($CLJS.ki,$CLJS.tA)(a)],null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qA,b,$CLJS.zz($CLJS.qD,$CLJS.U)(a)],null)};m7=function(a){return $CLJS.R.j(a,$CLJS.Vs,$CLJS.E4)};n7=function(a,b){return $CLJS.E.g($CLJS.lD.h(a),$CLJS.Cl(b,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.DD,$CLJS.kD,$CLJS.CD],null)))};
nta=function(a){return $CLJS.R0(a,new $CLJS.h(null,1,[$CLJS.DD,$CLJS.mh],null))};
ota=function(a,b){var c=$CLJS.Me(function(e){return $CLJS.rG(function(f){return $CLJS.E.g($CLJS.J.g(f,e),a)},b)},new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.NY,$CLJS.U],null));if($CLJS.n(c))return c;c=$CLJS.Mz($CLJS.Ex);if($CLJS.n($CLJS.Lz("metabase.lib.field",c))){var d=$CLJS.hD("Invalid :field clause: column {0} does not exist. Found: {1}",$CLJS.H([$CLJS.xh.l($CLJS.H([a])),$CLJS.xh.l($CLJS.H([$CLJS.Vj.g($CLJS.NY,b)]))]));d instanceof Error?$CLJS.Kz("metabase.lib.field",c,$CLJS.xv(),d):$CLJS.Kz("metabase.lib.field",
c,$CLJS.xv.l($CLJS.H([d])),null)}return null};
pta=function(a,b,c){if($CLJS.n(o7))return null;var d=o7;o7=!0;try{var e=$CLJS.J1(a,b),f=$CLJS.n(e)?$CLJS.IV(a,e):$CLJS.IV(a,b),k=function(){var m=$CLJS.p7.h(f);if($CLJS.n(m))return m;m=$CLJS.Bz(f,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rU,$CLJS.FV],null));if($CLJS.n(m))return m;m=$CLJS.n(function(){var u=$CLJS.RU.h(f);if($CLJS.n(u))return u;u=$CLJS.iO.h(f);if($CLJS.n(u))return u;u=$CLJS.cP.h(f);return $CLJS.n(u)?u:$CLJS.TD.h(f)}())?$CLJS.Z_.j(a,b,f):null;if($CLJS.n(m))return m;m=$CLJS.Mz($CLJS.Ex);if($CLJS.n($CLJS.Lz("metabase.lib.field",
m))){var t=$CLJS.hD("Cannot resolve column {0}: stage has no metadata",$CLJS.H([$CLJS.xh.l($CLJS.H([c]))]));return t instanceof Error?$CLJS.Kz("metabase.lib.field",m,$CLJS.xv(),t):$CLJS.Kz("metabase.lib.field",m,$CLJS.xv.l($CLJS.H([t])),null)}return null}(),l=function(){var m=$CLJS.y(k);return m?ota(c,k):m}();return $CLJS.n(l)?$CLJS.n(e)?$CLJS.R.j($CLJS.R.j($CLJS.k3($CLJS.Mj.l(l,$CLJS.Di,$CLJS.H([$CLJS.bK,$CLJS.RV,$CLJS.MV])),null),$CLJS.U,function(){var m=$CLJS.NY.h(l);return $CLJS.n(m)?m:$CLJS.U.h(l)}()),
$CLJS.MJ,$CLJS.zY):l:null}finally{o7=d}};
q7=function(a,b,c){$CLJS.I(c,0,null);var d=$CLJS.I(c,1,null),e=$CLJS.O(d);d=$CLJS.J.g(e,$CLJS.xO);var f=$CLJS.I(c,2,null);c=$CLJS.lk.l($CLJS.H([function(){var k=$CLJS.tA.h(e);return $CLJS.n(k)?new $CLJS.h(null,1,[$CLJS.tA,k],null):null}(),function(){var k=$CLJS.zz($CLJS.ki,$CLJS.tA)(e);return $CLJS.n(k)?new $CLJS.h(null,1,[$CLJS.ki,k],null):null}(),function(){var k=$CLJS.xQ.h(e);return $CLJS.n(k)?new $CLJS.h(null,1,[$CLJS.RV,k],null):null}(),function(){var k=$CLJS.rF.h(e);return $CLJS.n(k)?new $CLJS.h(null,
1,[$CLJS.MV,k],null):null}(),$CLJS.Ad(f)?function(){var k=$CLJS.P1(a,b,f);return $CLJS.n(k)?k:new $CLJS.h(null,2,[$CLJS.Vs,$CLJS.KJ,$CLJS.U,$CLJS.p.h(f)],null)}():$CLJS.n(d)?new $CLJS.h(null,2,[$CLJS.Vs,$CLJS.KJ,$CLJS.U,$CLJS.p.h(f)],null):function(){var k=pta(a,b,f);return $CLJS.n(k)?k:new $CLJS.h(null,2,[$CLJS.Vs,$CLJS.KJ,$CLJS.U,$CLJS.p.h(f)],null)}()]));return $CLJS.n(d)?$CLJS.k3(c,d):c};
r7=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.MV);return $CLJS.n($CLJS.n(b)?$CLJS.Cd($CLJS.oG,b):b)?$CLJS.Ti:$CLJS.zz($CLJS.ki,$CLJS.tA)(a)};
$CLJS.s7=function(a,b,c,d){$CLJS.I(d,0,null);var e=$CLJS.I(d,1,null),f=$CLJS.O(e),k=$CLJS.J.g(f,$CLJS.yD),l=$CLJS.J.g(f,$CLJS.tA),m=$CLJS.J.g(f,$CLJS.xQ),t=$CLJS.J.g(f,$CLJS.ki);e=$CLJS.J.g(f,$CLJS.xO);var u=$CLJS.J.g(f,$CLJS.IM),v=$CLJS.J.g(f,$CLJS.rF),x=$CLJS.lk.l;k=new $CLJS.h(null,2,[$CLJS.Vs,$CLJS.KJ,$CLJS.E0,k],null);f=$CLJS.mD.h(f);a=$CLJS.n(f)?f:$CLJS.m_.j(a,b,d);c=x.call($CLJS.lk,$CLJS.H([k,c,new $CLJS.h(null,1,[$CLJS.mD,a],null)]));c=$CLJS.n(t)?$CLJS.R.j(c,$CLJS.ki,t):c;l=$CLJS.n(l)?$CLJS.R.j(c,
$CLJS.tA,l):c;v=$CLJS.n(v)?$CLJS.R.j(l,$CLJS.MV,v):l;m=$CLJS.n(m)?$CLJS.R.j(v,$CLJS.RV,m):v;u=$CLJS.n(u)?$CLJS.R.j(m,$CLJS.aK,u):m;return $CLJS.n(e)?$CLJS.k3(u,e):u};t7=function(a,b,c){return $CLJS.n($CLJS.Me(function(d){return $CLJS.E.g($CLJS.bi.h(d),c)},a))?$CLJS.Vj.g(function(d){var e=$CLJS.Cd(d,b)?$CLJS.Mj.l(d,d,$CLJS.H([b])):d;return $CLJS.E.g($CLJS.bi.h(d),c)?$CLJS.R.j(e,b,!0):e},a):a};
u7=function(a){var b=$CLJS.n($CLJS.q1.h(a))?null:function(){var e=$CLJS.MJ.h(a),f=new $CLJS.Rg(null,new $CLJS.h(null,3,[$CLJS.PY,null,$CLJS.WY,null,$CLJS.zY,null],null),null);return f.h?f.h(e):f.call(null,e)}(),c=$CLJS.lk.l($CLJS.H([new $CLJS.h(null,3,[$CLJS.yD,$CLJS.p.h($CLJS.JD()),$CLJS.tA,$CLJS.tA.h(a),$CLJS.ki,r7(a)],null),function(){var e=function(){var f=$CLJS.Ra(b);return f?(f=$CLJS.Ra($CLJS.aK.h(a)))?(f=$CLJS.Sj.g($CLJS.JY,$CLJS.MJ.h(a)))?$CLJS.$Y.h(a):f:f:f}();return $CLJS.n(e)?new $CLJS.h(null,
1,[$CLJS.xO,e],null):null}(),function(){var e=$CLJS.j1(a);return $CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.xO,e],null):null}(),function(){var e=$CLJS.MV.h(a);return $CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.rF,e],null):null}(),function(){var e=$CLJS.QV.h(a);return $CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.QV,e],null):null}(),function(){var e=$CLJS.RV.h(a);return $CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.xQ,e],null):null}(),function(){var e=$CLJS.aK.h(a);return $CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.IM,e],null):null}()])),
d=($CLJS.n(b)?$CLJS.zz($CLJS.NY,$CLJS.U):$CLJS.zz($CLJS.Di,$CLJS.U))(a);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lF,c,d],null)};qta=function(a,b){return $CLJS.ff(function(c){return $CLJS.E.g($CLJS.MJ.h(c),$CLJS.HY)},$CLJS.Z_.v(a,b,$CLJS.IV(a,b),new $CLJS.h(null,3,[$CLJS.G_,!1,$CLJS.M_,!0,$CLJS.C_,!1],null)))};rta=new $CLJS.M("lib","simple-name","lib/simple-name",-734346338);sta=new $CLJS.M("metabase.lib.field","field-values-search-info","metabase.lib.field/field-values-search-info",-468454179);
$CLJS.v7=new $CLJS.M(null,"search-field-id","search-field-id",-699326724);w7=new $CLJS.M("metabase.lib.field","field-values-search-info.has-field-values","metabase.lib.field/field-values-search-info.has-field-values",1753198226);x7=new $CLJS.M(null,"parent-id","parent-id",-1400729131);$CLJS.p7=new $CLJS.M("metabase.lib.stage","cached-metadata","metabase.lib.stage/cached-metadata",-1406479151);y7=new $CLJS.M("metabase.lib.field","simple-display-name","metabase.lib.field/simple-display-name",494797345);
tta=new $CLJS.M(null,"earliest","earliest",-1928154382);uta=new $CLJS.M(null,"latest","latest",24323665);$CLJS.S0.m(null,$CLJS.lF,function(a){var b=$CLJS.I(a,0,null),c=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mh.h(b),$CLJS.R0(c,new $CLJS.h(null,2,[$CLJS.rF,$CLJS.mh,$CLJS.xQ,nta],null)),a],null)});
var o7=!1,z7=function z7(a,b){var d=$CLJS.a1(a,x7.h(b));a=$CLJS.n(x7.h(d))?z7.g?z7.g(a,d):z7.call(null,a,d):d;a=$CLJS.O(a);var e=$CLJS.J.g(a,$CLJS.U),f=$CLJS.J.g(a,$CLJS.mD);return $CLJS.Wj.j($CLJS.R.j($CLJS.Wj.j($CLJS.R.j(b,rta,$CLJS.U.h(b)),$CLJS.U,function(k){return[$CLJS.p.h(e),".",$CLJS.p.h(k)].join("")}),y7,$CLJS.mD.h(b)),$CLJS.mD,function(k){return[$CLJS.p.h(f),": ",$CLJS.p.h(k)].join("")})};$CLJS.R_.m(null,$CLJS.KJ,function(a,b,c){return r7(c)});
$CLJS.R_.m(null,$CLJS.lF,function(a,b,c){$CLJS.I(c,0,null);var d=$CLJS.I(c,1,null);d=$CLJS.O(d);d=$CLJS.J.g(d,$CLJS.rF);$CLJS.I(c,2,null);c=q7(a,b,c);c=$CLJS.n(d)?$CLJS.R.j(c,$CLJS.MV,d):c;return $CLJS.T_.j(a,b,c)});$CLJS.U_.m(null,$CLJS.KJ,function(a,b,c){a=$CLJS.O(c);b=$CLJS.J.g(a,$CLJS.U);return $CLJS.R.j(a,$CLJS.U,b)});$CLJS.U_.m(null,$CLJS.lF,function(a,b,c){var d=q7(a,b,c);b=$CLJS.s7(a,b,d,c);return $CLJS.n(x7.h(b))?z7(a,b):b});
$CLJS.N_.m(null,$CLJS.KJ,function(a,b,c,d){function e(T){var da=$CLJS.k5(l,k);return $CLJS.ha.j?$CLJS.ha.j("%s: %s",T,da):$CLJS.ha.call(null,"%s: %s",T,da)}function f(T){var da=$CLJS.cA($CLJS.Gz($CLJS.Xg(u),"-"," "));return $CLJS.ha.j?$CLJS.ha.j("%s: %s",T,da):$CLJS.ha.call(null,"%s: %s",T,da)}var k=$CLJS.O(c),l=$CLJS.J.g(k,$CLJS.RV),m=$CLJS.J.g(k,$CLJS.aK),t=$CLJS.J.g(k,$CLJS.bK),u=$CLJS.J.g(k,$CLJS.bi),v=$CLJS.J.g(k,$CLJS.$Y),x=$CLJS.J.g(k,y7),A=$CLJS.J.g(k,$CLJS.U),C=$CLJS.J.g(k,$CLJS.K5),G=$CLJS.J.g(k,
$CLJS.mD),K=$CLJS.J.g(k,x7),S=$CLJS.h1.g($CLJS.xA,A);c=function(){if($CLJS.n(x))return x;if($CLJS.n($CLJS.n(K)?null==G||$CLJS.E.g(G,S):K)){a:{var T=$CLJS.O(k);var da=$CLJS.J.g(T,$CLJS.mD);T=$CLJS.J.g(T,x7);for(da=new $CLJS.Vd(null,da,null,1,null);;)if($CLJS.n(T)){T=$CLJS.a1(a,T);var oa=$CLJS.O(T);T=$CLJS.J.g(oa,$CLJS.mD);oa=$CLJS.J.g(oa,x7);da=$CLJS.Yd.g(da,T);T=oa}else break a}da=$CLJS.Le($CLJS.Sa,da)?$CLJS.hs(": ",da):null}else da=null;return $CLJS.n(da)?da:$CLJS.n(G)?G:"string"===typeof A?S:$CLJS.p.h(A)}();
var X=$CLJS.E.g(d,$CLJS.n_)&&-1==c.indexOf(" → ")?function(){if($CLJS.n(m)){var T=$CLJS.a1(a,m);$CLJS.n(T)?T=$CLJS.g3($CLJS.mD.h($CLJS.p_.j(a,b,T))):(T=$CLJS.i1(a,t),T=$CLJS.m_.v(a,b,T,d))}else T=null;return $CLJS.n(T)?T:$CLJS.n(v)?v:$CLJS.j1(k)}():null;c=$CLJS.n(X)?[$CLJS.p.h(X)," → ",$CLJS.p.h(c)].join(""):c;return $CLJS.n(function(){var T=$CLJS.Sj.g(d,$CLJS.n_);return T?C:T}())?c:$CLJS.n($CLJS.n(u)?$CLJS.Sj.g(c,f(S)):u)?f(c):$CLJS.n($CLJS.n(l)?$CLJS.Sj.g(c,e(S)):l)?e(c):c});
$CLJS.N_.m(null,$CLJS.lF,function(a,b,c,d){$CLJS.I(c,0,null);var e=$CLJS.I(c,1,null),f=$CLJS.O(e);e=$CLJS.J.g(f,$CLJS.xQ);var k=$CLJS.J.g(f,$CLJS.xO),l=$CLJS.J.g(f,$CLJS.rF);f=$CLJS.J.g(f,$CLJS.IM);$CLJS.I(c,2,null);c=q7(a,b,c);c=$CLJS.n(k)?$CLJS.R.j(c,$CLJS.$Y,k):c;l=$CLJS.n(l)?$CLJS.R.j(c,$CLJS.bi,l):c;e=$CLJS.n(e)?$CLJS.R.j(l,$CLJS.RV,e):l;e=$CLJS.n(f)?$CLJS.R.j(e,$CLJS.aK,f):e;return $CLJS.n(e)?$CLJS.m_.v(a,b,e,d):$CLJS.KD("[Unknown Field]")});
$CLJS.O_.m(null,$CLJS.KJ,function(a,b,c){a=$CLJS.O(c);return $CLJS.J.g(a,$CLJS.U)});$CLJS.O_.m(null,$CLJS.lF,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=q7(a,b,c);return $CLJS.n(c)?$CLJS.P_.j(a,b,c):"unknown_field"});
$CLJS.V_.m(null,$CLJS.KJ,function(a,b,c){return $CLJS.lk.l($CLJS.H([function(){var d=$CLJS.kH($CLJS.V_,$CLJS.Oh);return d.j?d.j(a,b,c):d.call(null,a,b,c)}(),new $CLJS.h(null,2,[$CLJS.mD,$CLJS.m_.j(a,b,c),$CLJS.o_,$CLJS.m_.v(a,b,c,$CLJS.n_)],null),$CLJS.E.g($CLJS.MJ.h(c),$CLJS.PY)?function(){var d=$CLJS.ZY.h(c);return $CLJS.n(d)?(d=$CLJS.g_(a,d),$CLJS.n(d)?new $CLJS.h(null,1,[$CLJS.yj,new $CLJS.h(null,2,[$CLJS.U,$CLJS.U.h(d),$CLJS.mD,$CLJS.U.h(d)],null)],null):null):null}():null]))});
$CLJS.v0.m(null,$CLJS.lF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);return $CLJS.rF.h(b)});$CLJS.v0.m(null,$CLJS.KJ,function(a){return $CLJS.MV.h(a)});
$CLJS.u0.m(null,$CLJS.lF,function(a,b){$CLJS.I(a,0,null);var c=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);if($CLJS.n(b)){var d=$CLJS.Cd($CLJS.oG,b),e=$CLJS.qB($CLJS.QV,$CLJS.ki,$CLJS.tA)(c);c=$CLJS.R.l(c,$CLJS.rF,b,$CLJS.H([$CLJS.ki,d?$CLJS.Ti:e,$CLJS.QV,e]));return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lF,c,a],null)}b=$CLJS.QV.h(c);c=$CLJS.n(b)?$CLJS.Mj.g($CLJS.R.j(c,$CLJS.ki,b),$CLJS.QV):c;c=$CLJS.Mj.g(c,$CLJS.rF);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lF,c,a],null)});
$CLJS.u0.m(null,$CLJS.KJ,function(a,b){return $CLJS.n(b)?$CLJS.R.l(a,$CLJS.MV,b,$CLJS.H([$CLJS.QV,$CLJS.qB($CLJS.QV,$CLJS.ki,$CLJS.tA)(a)])):$CLJS.Mj.l(a,$CLJS.MV,$CLJS.H([$CLJS.QV]))});$CLJS.x0.m(null,$CLJS.lF,function(a,b,c){return $CLJS.y0.j(a,b,q7(a,b,c))});
$CLJS.x0.m(null,$CLJS.KJ,function(a,b,c){if($CLJS.Sj.g($CLJS.MJ.h(c),$CLJS.HY)){a=$CLJS.zz($CLJS.ki,$CLJS.tA)(c);b=null==c?null:$CLJS.WM.h(c);if(null==b)var d=null;else try{var e=$CLJS.Ak.h($CLJS.Mi.h(b));if($CLJS.n(e)){var f=$CLJS.O(e),k=$CLJS.J.g(f,tta),l=$CLJS.J.g(f,uta),m=$CLJS.kpa.l($CLJS.H([$CLJS.s0.h(k),$CLJS.s0.h(l)]));d=isNaN(m)?null:$CLJS.n($CLJS.Bq.g?$CLJS.Bq.g(1,m):$CLJS.Bq.call(null,1,m))?$CLJS.xk:$CLJS.n($CLJS.Bq.g?$CLJS.Bq.g(31,m):$CLJS.Bq.call(null,31,m))?$CLJS.Kk:$CLJS.n($CLJS.Bq.g?
$CLJS.Bq.g(365,m):$CLJS.Bq.call(null,365,m))?$CLJS.Oi:$CLJS.Ui}else d=null}catch(t){if(t instanceof Error)d=null;else throw t;}e=$CLJS.Dz(a,$CLJS.Ak)?$CLJS.vpa:$CLJS.Dz(a,$CLJS.tk)?$CLJS.upa:$CLJS.Dz(a,$CLJS.Lk)?$CLJS.tpa:$CLJS.xf;d=$CLJS.n(d)?t7(e,$CLJS.Oh,d):e;return $CLJS.n($CLJS.MV.h(c))?t7(d,$CLJS.u_,$CLJS.MV.h(c)):d}return $CLJS.xf});
$CLJS.h5.m(null,$CLJS.lF,function(a){var b=null==a?null:$CLJS.GD(a);b=null==b?null:$CLJS.xQ.h(b);return null==b?null:$CLJS.R.l(b,$CLJS.Vs,$CLJS.m5,$CLJS.H([$CLJS.l5,function(c,d){return q7(c,d,a)}]))});$CLJS.h5.m(null,$CLJS.KJ,function(a){var b=null==a?null:$CLJS.RV.h(a);return null==b?null:$CLJS.R.l(b,$CLJS.Vs,$CLJS.m5,$CLJS.H([$CLJS.l5,$CLJS.Pe(a)]))});$CLJS.n5.m(null,$CLJS.lF,function(a,b){return $CLJS.HD(a,$CLJS.FD,$CLJS.H([$CLJS.xQ,b]))});
$CLJS.n5.m(null,$CLJS.KJ,function(a,b){return $CLJS.FD(a,$CLJS.RV,b)});$CLJS.o5.m(null,$CLJS.lF,function(a,b,c){return $CLJS.p5.j(a,b,q7(a,b,c))});
$CLJS.o5.m(null,$CLJS.KJ,function(a,b,c){b=$CLJS.O(c);c=$CLJS.J.g(b,$CLJS.ki);var d=$CLJS.J.g(b,$CLJS.WM),e=$CLJS.J.g(b,$CLJS.jj);if($CLJS.Sj.g($CLJS.MJ.h(b),$CLJS.HY)){var f=function(){var m=null==a?null:$CLJS.$0(a);m=null==m?null:$CLJS.dz.h(m);return null==m?null:$CLJS.Cd(m,$CLJS.xQ)}(),k=$CLJS.Bz(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Mi,$CLJS.fj],null)),l=$CLJS.i5(b);return function u(t){return new $CLJS.ie(null,function(){for(;;){var v=$CLJS.y(t);if(v){if($CLJS.vd(v)){var x=$CLJS.ic(v),A=$CLJS.D(x),
C=$CLJS.le(A);return function(){for(var K=0;;)if(K<A){var S=$CLJS.hd(x,K),X=C,T=S;S=n7(S,l)?$CLJS.R.j(T,$CLJS.u_,!0):T;X.add(S);K+=1}else return!0}()?$CLJS.oe($CLJS.qe(C),u($CLJS.jc(v))):$CLJS.oe($CLJS.qe(C),null)}var G=$CLJS.z(v);return $CLJS.ae(function(){var K=G;return n7(G,l)?$CLJS.R.j(K,$CLJS.u_,!0):K}(),u($CLJS.Hc(v)))}return null}},null,null)}($CLJS.Ra(function(){if($CLJS.n(f)){var t=$CLJS.ni.h(k);return $CLJS.n(t)?$CLJS.sj.h(k):t}return f}())?null:$CLJS.Dz(e,$CLJS.Vi)?$CLJS.Vj.g(m7,new $CLJS.P(null,
5,5,$CLJS.Q,[$CLJS.j5(),new $CLJS.h(null,2,[$CLJS.mD,$CLJS.KD("Bin every 0.1 degrees"),$CLJS.lD,new $CLJS.h(null,2,[$CLJS.DD,$CLJS.CD,$CLJS.CD,.1],null)],null),new $CLJS.h(null,2,[$CLJS.mD,$CLJS.KD("Bin every 1 degree"),$CLJS.lD,new $CLJS.h(null,2,[$CLJS.DD,$CLJS.CD,$CLJS.CD,1],null)],null),new $CLJS.h(null,2,[$CLJS.mD,$CLJS.KD("Bin every 10 degrees"),$CLJS.lD,new $CLJS.h(null,2,[$CLJS.DD,$CLJS.CD,$CLJS.CD,10],null)],null),new $CLJS.h(null,2,[$CLJS.mD,$CLJS.KD("Bin every 20 degrees"),$CLJS.lD,new $CLJS.h(null,
2,[$CLJS.DD,$CLJS.CD,$CLJS.CD,20],null)],null)],null)):$CLJS.Dz(c,$CLJS.fj)&&!$CLJS.Dz(e,$CLJS.Zh)?$CLJS.Vj.g(m7,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.j5(),new $CLJS.h(null,2,[$CLJS.mD,$CLJS.KD("10 bins"),$CLJS.lD,new $CLJS.h(null,2,[$CLJS.DD,$CLJS.kD,$CLJS.kD,10],null)],null),new $CLJS.h(null,2,[$CLJS.mD,$CLJS.KD("50 bins"),$CLJS.lD,new $CLJS.h(null,2,[$CLJS.DD,$CLJS.kD,$CLJS.kD,50],null)],null),new $CLJS.h(null,2,[$CLJS.mD,$CLJS.KD("100 bins"),$CLJS.lD,new $CLJS.h(null,2,[$CLJS.DD,$CLJS.kD,$CLJS.kD,
100],null)],null)],null)):null)}return $CLJS.xf});$CLJS.rV.m(null,$CLJS.lF,function(a){return a});
$CLJS.rV.m(null,$CLJS.KJ,function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.MJ);switch(b instanceof $CLJS.M?b.S:null){case "source/aggregations":return b=new $CLJS.h(null,3,[$CLJS.yD,$CLJS.p.h($CLJS.JD()),$CLJS.ki,$CLJS.zz($CLJS.ki,$CLJS.tA)(a),$CLJS.CK,$CLJS.U.h(a)],null),a=$CLJS.E0.h(a),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.aF,b,a],null);case "source/expressions":return l7(a);case "source/fields":case "source/breakouts":return $CLJS.n($CLJS.qD.h(a))?l7(a):u7(a);default:return u7(a)}});
$CLJS.vta=function(){function a(e,f,k){k=$CLJS.Be($CLJS.Vj.g($CLJS.tV,k));var l=qta(e,f),m=$CLJS.Wf.j($CLJS.Sg,$CLJS.ul(function(t){return $CLJS.V1.v(e,f,t,l)}),$CLJS.n(k)?k:$CLJS.xf);m=$CLJS.Uj.g(m,l);k=$CLJS.n(k)?$CLJS.Wf.j(k,$CLJS.cf.h($CLJS.tV),m):null;return $CLJS.TV.l(e,f,$CLJS.FD,$CLJS.H([$CLJS.TD,k]))}function b(e,f){return d.j?d.j(e,-1,f):d.call(null,e,-1,f)}function c(e){return function(f,k){return d.j?d.j(f,k,e):d.call(null,f,k,e)}}var d=null;d=function(e,f,k){switch(arguments.length){case 1:return c.call(this,
e);case 2:return b.call(this,e,f);case 3:return a.call(this,e,f,k)}throw Error("Invalid arity: "+arguments.length);};d.h=c;d.g=b;d.j=a;return d}();$CLJS.A7=function(){function a(d,e){return $CLJS.TD.h($CLJS.IV(d,e))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.wta=function(){function a(d,e){var f=$CLJS.Z_.v(d,e,$CLJS.IV(d,e),new $CLJS.h(null,3,[$CLJS.G_,!1,$CLJS.M_,!1,$CLJS.C_,!1],null)),k=$CLJS.A7.g(d,e);return $CLJS.od(k)?$CLJS.Vj.g(function(l){return $CLJS.R.j(l,$CLJS.OY,!0)},f):$CLJS.i3(d,e,f,k)}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.Y(w7,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.br,$CLJS.Yi,$CLJS.CY,$CLJS.cz],null));
$CLJS.Y(sta,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.rj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.VJ,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.AD],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.v7,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.AD],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.VY,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,w7],null)],null)],null));