var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./shadow.js.shim.module$ttag.js");require("./clojure.string.js");require("./metabase.util.malli.registry.js");
'use strict';var Wga,Xga,Yga,Zga,$ga,aha,bha,PF,cha,dha,eha,fha,gha,hha,iha,jha,RF,kha;$CLJS.LF=new $CLJS.M(null,"supported-field","supported-field",-2061545519);Wga=new $CLJS.M("mbql.clause","distinct","mbql.clause/distinct",843025748);$CLJS.MF=new $CLJS.M("metabase.lib.schema.aggregation","aggregations","metabase.lib.schema.aggregation/aggregations",-203649172);Xga=new $CLJS.M("mbql.clause","count-where","mbql.clause/count-where",2010666417);
Yga=new $CLJS.M("mbql.clause","sum-where","mbql.clause/sum-where",-1599647617);$CLJS.NF=new $CLJS.M(null,"requires-column?","requires-column?",1623131448);Zga=new $CLJS.M("metabase.lib.schema.aggregation","operator","metabase.lib.schema.aggregation/operator",2070038764);$CLJS.OF=new $CLJS.M(null,"display-info","display-info",-816930907);$ga=new $CLJS.M("mbql.clause","max","mbql.clause/max",1615385533);aha=new $CLJS.M("mbql.clause","cum-count","mbql.clause/cum-count",-1408720642);
bha=new $CLJS.M("mbql.clause","median","mbql.clause/median",-1026386338);PF=new $CLJS.M("metabase.lib.schema.aggregation","percentile.percentile","metabase.lib.schema.aggregation/percentile.percentile",-144637711);cha=new $CLJS.M("mbql.clause","min","mbql.clause/min",2074448531);dha=new $CLJS.M("mbql.clause","percentile","mbql.clause/percentile",2064253651);eha=new $CLJS.M("mbql.clause","stddev","mbql.clause/stddev",659381076);fha=new $CLJS.M("mbql.clause","var","mbql.clause/var",809434920);
gha=new $CLJS.M("mbql.clause","share","mbql.clause/share",-37921592);hha=new $CLJS.M("mbql.clause","count","mbql.clause/count",-454225910);iha=new $CLJS.M("mbql.clause","cum-sum","mbql.clause/cum-sum",468410018);$CLJS.QF=new $CLJS.M(null,"driver-feature","driver-feature",1905324248);jha=new $CLJS.M("mbql.clause","avg","mbql.clause/avg",1826863173);RF=new $CLJS.M("metabase.lib.schema.aggregation","aggregation","metabase.lib.schema.aggregation/aggregation",-736862750);
$CLJS.SF=new $CLJS.M("operator","aggregation","operator/aggregation",-1961510924);kha=new $CLJS.M("mbql.clause","sum","mbql.clause/sum",1691012107);$CLJS.AE($CLJS.Rw,$CLJS.H([$CLJS.Zr,$CLJS.Ti,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qA,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lq,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.ZD],null)],null)],null)],null)]));$CLJS.AE($CLJS.mF,$CLJS.H([$CLJS.Zr,$CLJS.Ti,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qA,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lq,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.ZD],null)],null)],null)],null)]));
$CLJS.yE($CLJS.fF,$CLJS.H([$CLJS.Zr,$CLJS.LC,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.jE],null)],null)]));$CLJS.yE($CLJS.QE,$CLJS.H([$CLJS.Zr,$CLJS.Ti,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.ZD],null)],null)]));$CLJS.yE($CLJS.uF,$CLJS.H([$CLJS.Zr,$CLJS.Ti,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.YD],null)],null)]));
$CLJS.yE($CLJS.sj,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.eE],null)],null)]));$CLJS.rE($CLJS.sj,$CLJS.dE);$CLJS.yE($CLJS.yF,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.jE],null)],null)]));$CLJS.rE($CLJS.yF,$CLJS.dE);$CLJS.yE($CLJS.ni,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.eE],null)],null)]));$CLJS.rE($CLJS.ni,$CLJS.dE);
$CLJS.Y(PF,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.kr,new $CLJS.h(null,1,[$CLJS.ts,"valid percentile"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.jE],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vi,new $CLJS.h(null,1,[$CLJS.ts,"percentile must be between zero and one"],null),function(a){return 0<=a&&1>=a}],null)],null));$CLJS.yE($CLJS.XE,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.jE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,PF],null)]));$CLJS.rE($CLJS.XE,$CLJS.dE);
$CLJS.yE($CLJS.sF,$CLJS.H([$CLJS.Zr,$CLJS.LC,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.YD],null)],null)]));$CLJS.yE($CLJS.zF,$CLJS.H([$CLJS.Zr,$CLJS.LC,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.jE],null)],null)]));$CLJS.yE($CLJS.xF,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.jE],null)],null)]));
$CLJS.yE($CLJS.GF,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.jE],null)],null)]));$CLJS.rE($CLJS.xF,$CLJS.dE);$CLJS.rE($CLJS.GF,$CLJS.dE);$CLJS.yE($CLJS.EF,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.jE],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.YD],null)],null)]));$CLJS.rE($CLJS.EF,$CLJS.dE);
$CLJS.yE($CLJS.RE,$CLJS.H([$CLJS.Zr,$CLJS.LC,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.jE],null)],null)]));$CLJS.Y(RF,new $CLJS.P(null,17,5,$CLJS.Q,[$CLJS.er,jha,hha,aha,Xga,Wga,$ga,bha,cha,dha,gha,eha,kha,iha,Yga,fha,$CLJS.al],null));$CLJS.Y($CLJS.MF,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Wi,new $CLJS.h(null,1,[$CLJS.ni,1],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,RF],null)],null));
$CLJS.TF=new $CLJS.P(null,10,5,$CLJS.Q,[new $CLJS.h(null,4,[$CLJS.eF,$CLJS.Rw,$CLJS.NF,!1,$CLJS.QF,$CLJS.FE,$CLJS.OF,function(){return new $CLJS.h(null,3,[$CLJS.mD,$CLJS.KD("Count of rows"),$CLJS.WE,$CLJS.KD("Count"),$CLJS.Hs,$CLJS.KD("Total number of rows in the answer.")],null)}],null),new $CLJS.h(null,5,[$CLJS.eF,$CLJS.xF,$CLJS.LF,$CLJS.pj,$CLJS.NF,!0,$CLJS.QF,$CLJS.FE,$CLJS.OF,function(){return new $CLJS.h(null,3,[$CLJS.mD,$CLJS.KD("Sum of ..."),$CLJS.WE,$CLJS.KD("Sum"),$CLJS.Hs,$CLJS.KD("Sum of all the values of a column.")],
null)}],null),new $CLJS.h(null,5,[$CLJS.eF,$CLJS.fF,$CLJS.LF,$CLJS.pj,$CLJS.NF,!0,$CLJS.QF,$CLJS.FE,$CLJS.OF,function(){return new $CLJS.h(null,3,[$CLJS.mD,$CLJS.KD("Average of ..."),$CLJS.WE,$CLJS.KD("Average"),$CLJS.Hs,$CLJS.KD("Average of all the values of a column")],null)}],null),new $CLJS.h(null,5,[$CLJS.eF,$CLJS.yF,$CLJS.LF,$CLJS.pj,$CLJS.NF,!0,$CLJS.QF,$CLJS.hF,$CLJS.OF,function(){return new $CLJS.h(null,3,[$CLJS.mD,$CLJS.KD("Median of ..."),$CLJS.WE,$CLJS.KD("Median"),$CLJS.Hs,$CLJS.KD("Median of all the values of a column")],
null)}],null),new $CLJS.h(null,5,[$CLJS.eF,$CLJS.QE,$CLJS.LF,$CLJS.ur,$CLJS.NF,!0,$CLJS.QF,$CLJS.FE,$CLJS.OF,function(){return new $CLJS.h(null,3,[$CLJS.mD,$CLJS.KD("Number of distinct values of ..."),$CLJS.WE,$CLJS.KD("Distinct values"),$CLJS.Hs,$CLJS.KD("Number of unique values of a column among all the rows in the answer.")],null)}],null),new $CLJS.h(null,5,[$CLJS.eF,$CLJS.GF,$CLJS.LF,$CLJS.pj,$CLJS.NF,!0,$CLJS.QF,$CLJS.FE,$CLJS.OF,function(){return new $CLJS.h(null,3,[$CLJS.mD,$CLJS.KD("Cumulative sum of ..."),
$CLJS.WE,$CLJS.KD("Sum"),$CLJS.Hs,$CLJS.KD("Additive sum of all the values of a column.\ne.x. total revenue over time.")],null)}],null),new $CLJS.h(null,4,[$CLJS.eF,$CLJS.mF,$CLJS.NF,!1,$CLJS.QF,$CLJS.FE,$CLJS.OF,function(){return new $CLJS.h(null,3,[$CLJS.mD,$CLJS.KD("Cumulative count of rows"),$CLJS.WE,$CLJS.KD("Count"),$CLJS.Hs,$CLJS.KD("Additive count of the number of rows.\ne.x. total number of sales over time.")],null)}],null),new $CLJS.h(null,5,[$CLJS.eF,$CLJS.zF,$CLJS.LF,$CLJS.pj,$CLJS.NF,
!0,$CLJS.QF,$CLJS.vF,$CLJS.OF,function(){return new $CLJS.h(null,3,[$CLJS.mD,$CLJS.KD("Standard deviation of ..."),$CLJS.WE,$CLJS.KD("SD"),$CLJS.Hs,$CLJS.KD("Number which expresses how much the values of a column vary among all rows in the answer.")],null)}],null),new $CLJS.h(null,5,[$CLJS.eF,$CLJS.ni,$CLJS.LF,$CLJS.Ci,$CLJS.NF,!0,$CLJS.QF,$CLJS.FE,$CLJS.OF,function(){return new $CLJS.h(null,3,[$CLJS.mD,$CLJS.KD("Minimum of ..."),$CLJS.WE,$CLJS.KD("Min"),$CLJS.Hs,$CLJS.KD("Minimum value of a column")],
null)}],null),new $CLJS.h(null,5,[$CLJS.eF,$CLJS.sj,$CLJS.LF,$CLJS.Ci,$CLJS.NF,!0,$CLJS.QF,$CLJS.FE,$CLJS.OF,function(){return new $CLJS.h(null,3,[$CLJS.mD,$CLJS.KD("Maximum of ..."),$CLJS.WE,$CLJS.KD("Max"),$CLJS.Hs,$CLJS.KD("Maximum value of a column")],null)}],null)],null);
$CLJS.Y(Zga,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.rj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Hq,$CLJS.SF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.eF,$CLJS.Wf.j(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.br],null),$CLJS.cf.h($CLJS.eF),$CLJS.TF)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.LF,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,$CLJS.Bi],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.NF,$CLJS.tr],null),new $CLJS.P(null,2,5,
$CLJS.Q,[$CLJS.QF,$CLJS.Bi],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.OF,$CLJS.id],null)],null));