var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.id.js");require("./metabase.mbql.schema.js");require("./metabase.util.malli.registry.js");
'use strict';var gU,hU,iU,kU,lU,mU,nU,oU,pU,qU;gU=new $CLJS.M("metabase.lib.schema.template-tag","widget-type","metabase.lib.schema.template-tag/widget-type",2021141560);hU=new $CLJS.M("metabase.lib.schema.template-tag","raw-value","metabase.lib.schema.template-tag/raw-value",-530604923);iU=new $CLJS.M("metabase.lib.schema.template-tag","template-tag","metabase.lib.schema.template-tag/template-tag",-1178540449);
$CLJS.jU=new $CLJS.M("metabase.lib.schema.template-tag","template-tag-map","metabase.lib.schema.template-tag/template-tag-map",1471181299);kU=new $CLJS.M("metabase.lib.schema.template-tag","type","metabase.lib.schema.template-tag/type",1926679295);lU=new $CLJS.M("metabase.lib.schema.template-tag","field-filter","metabase.lib.schema.template-tag/field-filter",1376344463);mU=new $CLJS.M("metabase.lib.schema.template-tag","snippet","metabase.lib.schema.template-tag/snippet",190689821);
nU=new $CLJS.M("metabase.lib.schema.template-tag","common","metabase.lib.schema.template-tag/common",1713198790);oU=new $CLJS.M("metabase.lib.schema.template-tag","value.common","metabase.lib.schema.template-tag/value.common",-974864959);pU=new $CLJS.M("metabase.lib.schema.template-tag","raw-value.type","metabase.lib.schema.template-tag/raw-value.type",-805651412);qU=new $CLJS.M("metabase.lib.schema.template-tag","source-query","metabase.lib.schema.template-tag/source-query",479361081);$CLJS.Y(gU,$CLJS.Wf.g(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.br,new $CLJS.h(null,1,[$CLJS.ts,"Valid template tag :widget-type"],null),$CLJS.cz],null),$CLJS.Ng($CLJS.bU)));$CLJS.Y(kU,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.br,$CLJS.KL,$CLJS.zM,$CLJS.EE,$CLJS.Ps,$CLJS.xj,$CLJS.FO],null));
$CLJS.Y(nU,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.rj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.U,$CLJS.rD],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mD,$CLJS.rD],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Di,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.er,$CLJS.rD,$CLJS.sr],null)],null)],null));
$CLJS.Y(oU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ss,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,nU],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Oh,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.al],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.aN,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.tr],null)],null)],null));
$CLJS.Y(lU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ss,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,oU],null),new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.rj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Mi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Hq,$CLJS.EE],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.EE,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.aE],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.UM,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,gU],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ji,new $CLJS.h(null,1,[$CLJS.yr,!0],
null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,$CLJS.rj],null)],null)],null)],null));
$CLJS.Y(mU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ss,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,nU],null),new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.rj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Mi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Hq,$CLJS.KL],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wL,$CLJS.rD],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.XO,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.iK],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.YO,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.XJ],null)],null)],null));
$CLJS.Y(qU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ss,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,nU],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Mi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Hq,$CLJS.zM],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fQ,$CLJS.WJ],null)],null)],null));$CLJS.Y(pU,$CLJS.Wf.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.br],null),$CLJS.QT));
$CLJS.Y(hU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ss,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,oU],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Mi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,pU],null)],null)],null)],null));
$CLJS.Y(iU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Mi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,kU],null)],null)],null),new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.jr,new $CLJS.h(null,1,[$CLJS.si,$CLJS.Mi],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.EE,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,lU],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.KL,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,mU],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zM,
new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,qU],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Wn,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,hU],null)],null)],null)],null));
$CLJS.Y($CLJS.jU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zi,$CLJS.rD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,iU],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vi,new $CLJS.h(null,1,[$CLJS.ts,"keys in template tag map must match the :name of their values"],null),function(a){return $CLJS.Le(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);return $CLJS.E.g(c,$CLJS.U.h(b))},a)}],null)],null));